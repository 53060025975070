import React from "react";

export const HelpPageTemplate = () => (
    <div></div>
);

const HelpPage = () => (
    <HelpPageTemplate></HelpPageTemplate>
)

export default HelpPage